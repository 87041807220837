<template>
  <!-- <v-container id="regular-tables" fluid tag="section"> -->
  <!-- <v-container class="grey lighten-5" id="product__container"> -->
 <div>
       <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
            <clientFilter
            :formType="formType"
            @search="search"
            :data="data"
            @reset="reset"
            @showButtons="showButtons"/>

        <v-dialog v-model="progressBar" fullscreen full-width>
           <v-container fluid fill-height style="background-color: rgba(200, 200, 200, 0.5);">
                <v-layout justify-center align-center>
                        <v-progress-circular class="text-center"
                            :rotate="360"
                            :size="100"
                            :width="10"
                            :value="downloadPer"
                            :transfer_rate="transfer_rate"
                             indeterminate
                             color="primary" >
                            <h4>{{downloadPer}}%<br>{{`${transfer_rate} KB/s`}}</h4>
                       </v-progress-circular>
                   </v-layout>
                  </v-container>
          </v-dialog>
        <v-row>
        <v-col cols="11"></v-col>
       <v-col cols="1" >
      <v-btn  style="" v-if="data.length > 0" color="primary" small @click.stop="exportReport()"  v-show="checkIfOperationExistForModule('export')">Export</v-btn>
       </v-col>
    </v-row>
 <v-row>
 <v-col cols="12" >
   <DataTable
   :headers="headers"
   ref="datatable"
    hide-default-footer
    disable-pagination
   :fixed_header="fixed_header"
   :data="data"
   :item_key="item_key"
   :show_check_box="show_check_box"
   :totRecords="totRecords"
   :perpage="perpage"
   :isLoading="isLoading"
   :showexportbutton = "showexportbutton"
   :editItem="editItem"
   :get_data_api_url="get_data_api_url"
   :sdate="sdate"
   :edate="edate"
   :formType="formType"
   :show_add_delete_buttons ="show_add_delete_buttons"
   @opendialog ="opendialog"/>
 </v-col>
   </v-row>
   </div>
   <!-- </div>
          </div> -->
  <!-- </v-container> -->
</template>
<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DataTable from "./component/DataTable.vue";
import clientFilter from './component/clientFilter.vue';
import { pixel_report } from "../../config";
import moment from 'moment'
import axios from "axios";


  export default {
    components: { Loading,DataTable,clientFilter },
    data () {
      return {
        endpoints:{

          "EXPORT_DATA_URL":null
        },
        progressBar:false,
        fixed_header:false,
        get_data_api_url:null,
        transfer_rate:0,
        data:[],
        item_key:"",
        totRecords :null,
        perpage:50,
        isLoading:false,
        page:1,
        headers: [

                {
                    text: 'Muid',
                    align: 'start',
                    value: 'muid',
                    width:"2%",
                     sortable: false,
                    class: "v-data-table-header ",
                     cellClass:"pa-1"
                },
                {
                    text: 'Product_id',
                    value: 'product_id',
                    sortable: false,
                    width:"5%",
                   class: "v-data-table-header",

                },
                                {
                    text: 'Entry_Id',
                    value: 'entry_id',
                    sortable: false,
                    width:"10%",
                    class: "v-data-table-header",
                    cellClass:"pa-0"
                },
                {
                    text: 'Additional_Companies',
                    value: 'additional_companies',

                     sortable: false,
                    width:"8%",
                    class: "v-data-table-header",

                },
                  {
                    text: 'X_Cord',
                    value: 'x_cord',
                     sortable: false,
                    width:"4%",
                    class: "v-data-table-header",
                },
                 {
                    text: 'Y_Cord',
                    value: 'y_cord',
                     sortable: false,
                    width:"4%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Area',
                    value: 'area',
                     sortable: false,
                    width:"3%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Width',
                    value: 'width',
                     sortable: false,
                    width:"3%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Height',
                    value: 'height',
                     sortable: false,
                    width:"4%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Color',
                    value: 'color',
                     sortable: false,
                    width:"4%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Camp_Img_Height',
                    value: 'campaign_img_height',
                     align: 'center',
                     sortable: false,
                    width:"8%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Camp_Img_Width',
                    value: 'campaign_img_width',
                      align: 'center',

                     sortable: false,
                    width:"8%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Type',
                    value: 'type',
                     sortable: false,
                    width:"2%",
                    class: "v-data-table-header",
                },
                {
                    text: 'ypos',
                    value: 'ypos',
                     sortable: false,
                    width:"2%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Pri_Company',
                    value: 'primary_company',
                      align: 'center',
                     sortable: false,
                    width:"8%",
                    class: "v-data-table-header",
                },
                 {
                    text: 'Weighted_Attributes',
                    value: 'weighted_attributes',
                     sortable: false,
                    width:"10%",
                    class: "v-data-table-header",
                },
                {
                    text: 'Attributed_Percentage',
                    value: 'attributed_percentage',
                     sortable: false,
                    width:"10%",
                    class: "v-data-table-header",
                },
                   {
                    text: 'PPEVE',
                    value: 'prod_est_email_vol',
                     sortable: false,
                    width:"3%",
                    class: "v-data-table-header",
                },

                {
                    text: 'RPV',
                    value: 'RPV',
                     sortable: false,
                    width:"2%",
                    class: "v-data-table-header",
                },
        ],
        fullPage: false,
        showButtons:true,
        // search_company_id:null,
        dialog:false,
        // added_company_id:null,
        updatedialog:false,
        // sender_email:null,
        // update_item_id:'',
        // company_name:'',
        show_add_delete_buttons:false,
        opendialog:false,
        show_check_box:false,
        editItem:false,
        showexportbutton:false,
        // totalloginCount:'',
        // totalTime:'',
        // companyName:'',
        sdate:'',
        edate:'',
        formType:"PIXEL_REPORT",
        downloadPer:0
      }
    },
    mounted() {
      var view = this
      view.get_data_api_url = view.$url("PIXEL_REPORT")
      view.endpoints["EXPORT_DATA_URL"] = view.$url("PIXEL_REPORT_DOWNLOAD")
      view.sdate =moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
      view.edate =moment(new Date()).format("YYYY-MM-DD")
      view.postdata(view.$url("PIXEL_REPORT"))
    },
    methods: {
      checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
      postdata:function(api_url){
        var view = this
        view.data = []
        view.fixed_header=true
        view.isLoading = true
        view.totRecords=null
        const Json_data = {
            "sdate":this.sdate,
            "edate":this.edate,
            "page_no":this.page
        }
         view.$utils.postData(api_url,view,Json_data).then((res)=>{

            view.data =JSON.parse(res.data.report_data)
            view.totRecords=res.data.total_record
            view.isLoading=false
          }).catch((error) => {
              console.error(error);
              view.isLoading=false
        });
      },
       search: function (value) {
         var view = this
         if(typeof value !== undefined){
           view.sdate = value["sdate"]
           view.edate = value["edate"]
           view.postdata( view.get_data_api_url)
        // this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
        // .post ( view.get_data_api_url, formdata)
        // .then((res, status, xhr) => {
          // view.isLoading = false;
        //      })
        }
    },
    reset:function(value){
        var view = this
        view.fixed_header=true
        view.page=1
        view.data=[]
        view.totRecords=0
        if(typeof value !== undefined){
           view.sdate = value["sdate"]
           view.edate = value["edate"]
           view.isLoading = true
           const Json_data = {
            "sdate":view.sdate,
            "edate": view.edate,
            "page_no":view.page
        }
        view.$utils.postData(view.get_data_api_url,view,Json_data).then((res)=>{
            view.data =JSON.parse(res.data.report_data)
            view.totRecords=res.data.total_record
            view.isLoading=false
          }).catch((error) => {
              console.error(error);
              view.isLoading=false
        });
         }
        // let base_url = view.endpoints["GET_DATA_URL"]
        // view.page = 1;
        // this.$refs.datatable.page = 1
        // this.totRecords=''
        // this.totalTime =''
        // window.location.href=base_url
        // this.data = []
    },
    // exportReport:function(){
    //        var view = this
    //        view.isLoading = true
    //        const Json_data = {
    //         "sdate":this.sdate,
    //         "edate":this.edate
    //          }
    //        //let ep = this.endpoints["EXPORT_DATA_URL"]+"?company_name="+this.companyName +"&sdate="+this.sdate+"&edate="+this.edate
    //        view.$utils.postData(view.endpoints["EXPORT_DATA_URL"],view,Json_data,
    //        {
    //          onDownloadProgress: (progressEvent) => {
    //         let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //         console.log("in the download")
    //         console.log(progressEvent.lengthComputable)
    //         console.log(percentCompleted);
    //           }
    //       }).then((res)=>{
    //         //window.location.href = view.endpoints["EXPORT_DATA_URL"]
    //         console.log("Res:",res )
    //         let blob = new Blob([res.data], { type: "text/csv" });
    //         var a = document.createElement('a');
    //         var url = window.URL.createObjectURL(blob);
    //         a.href = url;
    //         a.download = `${this.sdate}_to_${this.edate}_pixel_report.csv`;
    //         document.body.append(a);
    //         a.click();
    //         a.remove();
    //         window.URL.revokeObjectURL(url);
    //         view.isLoading=false
    //       }).catch((error) => {
    //           console.error(error);
    //           view.isLoading=false
    //     });
    // }
  exportReport:function(){
          var view = this
          view.fixed_header=true
          view.progressBar=true
          const Json_data = {
            "sdate":this.sdate,
            "edate":this.edate
             }
          var startTime = new Date().getTime();
          var config = {
                onDownloadProgress: (progressEvent) => {
                    var duration = ( new Date().getTime() - startTime ) / 1000;
                    var Bps = ((progressEvent.loaded)/ duration);
                    var KBps = Bps / 1024;
                    view.transfer_rate= Math.floor(KBps);
                    view.downloadPer = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                },
            };
          axios.post(view.endpoints["EXPORT_DATA_URL"],Json_data,config).then((res) => {
            let blob = new Blob([res.data], { type: "text/csv" });
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${this.sdate}_to_${this.edate}_pixel_report.csv`;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            view.progressBar=false
            view.downloadPer=0
            view.transfer_rate=0
            }).catch((error) => {
              console.error(error);
              view.isLoading=false
        });

      }
    },
  }
</script>
