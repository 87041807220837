<template>
    <div id="clientFilter">     
        <span style="color:red">{{errormsg}}</span>
        <v-container  id="searchForm__container">
            <!-- <v-expand-transition> -->
                
                    <v-row>
                        <v-col cols="3" v-if="formType !='PIXEL_REPORT'">
                              <v-select
                                   v-model="select"                                    
                                    :items="items"
                                    item-text="option"
                                    item-value="value"
                                    label="Select"
                                    persistent-hint
                                    return-object
                                    single-line
                                    >
                                </v-select>
                        </v-col>                         
                         <v-col cols="3">
                             <v-dialog
                                        ref="modal"
                                        v-model="modal"
                                        :return-value.sync="sdate"
                                        persistent
                                        width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                     no-title
                                        v-model="sdate"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="sdate" 
                                     no-title scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.modal.save(sdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="3">
                             <v-dialog
                                        ref="dialog"
                                        v-model="endatemodel"
                                        :return-value.sync="edate"
                                        persistent
                                        width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                     no-title
                                        v-model="edate"
                                        label="End Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="edate"
                                    no-title scrollable 
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="endatemodel = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(edate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                            </v-dialog>
                        </v-col>                      
                         <v-col cols="3" style="padding-top:20px">                           
                            <v-btn color="primary" small @click="searchData">Search</v-btn>
                            <v-btn color="primary" small @click="reset">Reset</v-btn>
                        </v-col>
                    </v-row>                             
        </v-container>
    </div>
</template>

<script>
import moment from 'moment'
import Autocomplete from "./Autocomplete.vue";
export default {
    name: 'searchForm',
    data: () => ({
        
        client_endpoint:'',
        errorMessage:{
            "date_er_message":"Please select start date and end date"
        },
        select: { option: 'All', value: '' },
        items:[],
        // items: [{"option":"All","value":''}],
          modal: false,
          endatemodel:false,
          errormsg:'',
          edate:'',
          sdate:'',
    }),
    components:{
        Autocomplete
    },
    props: [
        'formType',
        'stateName',
    ],
    mounted(){
        let view = this

        if (this.formType==="PIXEL_REPORT"){

        // this.sdate = this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate()-1));
        // this.edate = this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
        view.sdate =moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
        view.edate =moment(new Date()).format("YYYY-MM-DD")
        console.log("::AAA", this.sdate, this.edate )
        }
        else{
        view.client_endpoint = view.$url("GET_ALL_CLIENTS")
        view.$utils.getData(view.client_endpoint,view,'').then((res)=>{
            this.items = res.data.payload
            
          }).catch((error) => {
              alert(error)
              view.isLoading=false
        });            
        this.sdate = this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
        this.edate = this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
        }
    },
    methods: {
         formatDate(date) {
      var [date, month, year] = date.toLocaleDateString().split('/')
      return `${year}-${month}-${date}`;
    },
        
        searchData() {
            var view = this
            let d1 = new Date(this.sdate)
            let d2 = new Date(this.edate)
            if (d1.getTime() > d2.getTime())
            {
                alert("Start Date should not be greater then end date ")

            }
            else{
                let searchobj = {}
                searchobj["sdate"] = this.sdate
                searchobj["edate"] = this.edate
                //searchobj["company"] = this.select["value"]
                view.$emit('search',searchobj)
            }            
        },
        reset: function() {
             var view = this
              if (view.formType==="PIXEL_REPORT"){
                view.sdate =moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
                view.edate =moment(new Date()).format("YYYY-MM-DD")
                let resetobj = {}
                resetobj["sdate"] = view.sdate
                resetobj["edate"] = view.edate
                view.$emit('reset',resetobj)
              }
              else{ 
            view.select = ''
            view.$emit('reset')
             }
        },    
    },
    watch: {
    }
}
</script>
<style>

</style>

<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top:0px
#searchForm__container
  padding-top:0px
</style>
