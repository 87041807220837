<template>
  <div id="DataTable">
    <!-- <v-container   >  -->

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    />

    <v-row no-gutters>
      <v-col cols="12" style="padding-bottom: 15px">
        <!-- <div style="float: right">
                  <v-btn color="primary" small @click.stop="deleterecord()" >Delete</v-btn>
                </div> -->
        <div style="float: right">
          <v-btn
            color="primary"
            small
            @click.stop="addrecord()"
            v-show="checkIfOperationExistForModule('add')"
            >Add</v-btn
          >
          <v-btn
            color="error"
            v-show="checkIfOperationExistForModule('delete')"
            small
            @click.stop="deleteItem()"
            >Delete</v-btn
          >
        </div>
        <div style="float: right" v-if="showexportbutton">
          <v-btn
            v-if="data.length > 0"
            color="primary"
            small
            @click.stop="exportReport()"
            >Export
          </v-btn>
          <download-csv
            :data="exportData"
            id="exportButton"
            :name="csv_name"
            style="display: none"
          >
            Download Data
          </download-csv>
          <!-- <v-btn color="primary" small @click.stop="deleteItem()">Delete</v-btn> -->
        </div>
      </v-col>
    </v-row>
    <div class="ml-5 mr-5">
      <!-- <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      /> -->
      <v-data-table
        v-if="formType == 'PIXEL_REPORT'"
        :height="data.length > 10 ? '70vh' : 'auto'"
        :headers="headers"
        :fixed-header="fixed_header"
        :items="data"
        v-model="selected"
        :item-key="item_key"
        :single-select="false"
        :show-select="show_check_box"
        hide-default-footer
        :options.sync="options"
        :server-items-length="totRecords"
        :loading="isLoading"
        class="elevation-1"
      >

        <template v-slot:item.insert_update_date="{ item }">
          <div>
            {{ formateDate(item.insert_update_date) }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <div>
            <v-icon
              v-show="checkIfOperationExistForModule('edit')"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </div>
        </template>
      </v-data-table>

      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        v-model="selected"
        :item-key="item_key"
        :single-select="false"
        :show-select="show_check_box"
        hide-default-footer
        disable-pagination
        :options.sync="options"
        :server-items-length="totRecords"
        class="elevation-1"
      >
        <template v-slot:item.insert_update_date="{ item }">
          <div>
            {{ formateDate(item.insert_update_date) }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <div>
            <v-icon
              small
              @click="editItem(item)"
              v-show="checkIfOperationExistForModule('edit')"
              >mdi-pencil</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="text-center pt-4 pb-4">
      <Pagination
        :isloading="isLoading"
        :startRecord="startRecord"
        :currentPage="currentPage"
        :lastPage="lastPage"
        :lastRecord="lastRecord"
        :totRecords="totRecords"
        :isCurrentPageClass="isCurrentPageClass"
        :perpage="perpage"
        :getLastPageClass="getLastPageClass"
        :totPage="totPage"
        :getPages="getPages"
        @handlePerPage="handlePerPage"
        @paginate="paginate"
        @last="last"
        @getDataByPage="getDataByPage"
        :showPerPage="showPerPage"
      />
    </div>

    <!-- </v-container>  -->
  </div>
</template>
<script>
import Pagination from "./Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Loading, Pagination },
  name: "DataGrid",
  props: ["closecompanydialog", "search_company_id", "sender_email"],
  data: () => ({
    page: 1,
    options: {},
    fullPage: true,
    query: null,
    showPerPage: false,
    selected: [],
    // singleSelect: false,
    action_buttons: false,
    selected_item_ids: [],
    exportData: [],
    csv_name: "exportData",
    isLoading: false,
    is_search: false,
  }),
  props: [
    "headers",
    "data",
    "item_key",
    "show_check_box",
    "totRecords",
    "perpage",
    "isLoading",
    "get_data_api_url",
    "sdate",
    "edate",
    "formType",
    "show_add_delete_buttons",
    "showexportbutton",
    "fixed_header",
    "fullpage",
    "search_company_id",
    "sender_email",
    "is_search",
  ],
  mounted() {},
  computed: {
    //pagination computed metsender_emailhods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      var view = this;
      if (view.is_search) {
        this.page = 1;
      }
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getData(page) {
      var view = this;
      view.isLoading = true;
     if (view.formType==="PIXEL_REPORT"){
        view.data= []
        const Json_data = {
            "sdate":view.sdate,
            "edate":view.edate,
            "page_no":page
        }
         view.$utils.postData(view.get_data_api_url,view,Json_data).then((res)=>{
            view.data =JSON.parse(res.data.report_data)
            view.totRecords=res.data.total_record
            view.isLoading=false
          }).catch((error) => {
              console.error(error);
              view.isLoading=false
        });
        }
     else{
      let newurl = view.get_data_api_url + "?page=" + page;

      if((this.search_company_id !== ''  && this.search_company_id !==null) || (this.sender_email !== '' && this.sender_email != null)){
        let search_comp_id = this.search_company_id !== null?this.search_company_id:''
        let semail = this.sender_email !== null?this.sender_email:''
        newurl = view.get_data_api_url + "?company_id="+search_comp_id+"&sender_email="+semail+"&page=" + page;
      }

      view.$utils
        .getData(newurl, view, page)
        .then((res) => {

          view.data = res.data.payload.data;

          view.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
     }
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getData(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getData(this.page);
    },
    getDataByPage(value) {
      console.log(value);
      if(value >1){
        this.is_search = false
      }
      this.page = value;
      this.getData(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getData(this.page);
    },
    editItem(item) {
      var view = this;
      this.$emit("editItem", item);
    },
    addrecord: function () {
      this.$emit("opendialog", true);
    },
    deleteItem() {
      var view = this;
      view.selected_item_ids = [];
      view.selected.map((item) => {
        view.selected_item_ids.push(item.id);
      });
      view.selected = [];
      this.$emit("deleteItem", view.selected_item_ids);
    },
    delete() {
      var view = this;
      console.log(this.selected);
    },
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    exportReport: function () {

    },
  },
  watch: {},
};
</script>


